import React from "react";
import { gsap } from "gsap";
import "./index.sass";
// import './css/index.css'

import ComingSoon from "./ComingSoon";
import Navbar from "./Navbar";

const LOCATION = {
  HOME_PAGE: "/",
  FAQ_PAGE: "/faq",
  CONTACT_PAGE: "/contact",
  WHITEPAPER_PAGE: "/whitepaper",
  COMING_SOON_PAGE: "/coming-soon",
};

class App extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      location: window.location.pathname,
      isFixed: false,
      video: false,
      footerBackgroundLight: false,
      loading: true,
    };
  }

  updateTitle = (location) => {
    document.title = (() => {
      switch (location) {
        case LOCATION.HOME_PAGE:
          return "Welcome to FourthD";
        case LOCATION.FAQ_PAGE:
          return "0xBlock — Frequently Asked Questions";
        case LOCATION.CONTACT_PAGE:
          return "Contact us — Got a question or suggestion?";
        case LOCATION.WHITEPAPER_PAGE:
          return "Whitepaper — 0xBlock";
        case LOCATION.COMING_SOON_PAGE:
          return "Coming Soon — 0xBlock";
        default:
          return "404 not-found";
      }
    })();
  };

  componentDidMount() {
    window.document.onreadystatechange = () => {
      console.log("====document.readyState", window.document.readyState);
      this.setState({ loading: !document.readyState === "complete" });
    };
    this.setState({ location: window.location.pathname });
    this.updateTitle(window.location.pathname);
    window.location.pathname === "/"
      ? this.setState({ footerBackgroundLight: true })
      : this.setState({ footerBackgroundLight: false });
    window.addEventListener("scroll", this.handleScroll);
    gsap.to("#root section, #root .footer", {
      ease: "power3.out",
      opacity: 1,
      y: 0,
      duration: 0.3,
      delay: 0.5,
    });
  }

  componentWillUnmount() {
    window.removeEventListener("scroll", this.handleScroll);
  }

  handleScroll = (event) => {
    const scroll = window.scrollY;
    if (scroll > 0) {
      this.setState({ isFixed: true });
    } else {
      this.setState({ isFixed: false });
    }
    event.preventDefault();
  };

  titleHandler = () => {
    if (this.state.location !== window.location.pathname) {
      gsap.to("section, #root .footer, .gradient-mesh", {
        ease: "power3.out",
        opacity: 0,
        duration: 0.3,
        onComplete: () => {
          this.setState({ location: window.location.pathname });
          gsap.fromTo(
            "section, #root .footer, .gradient-mesh",
            {
              y: -50,
              opacity: 0,
            },
            {
              ease: "power3.out",
              y: 0,
              opacity: 1,
              duration: 1,
            }
          );
        },
      });
      window.location.pathname == "/"
        ? this.setState({ footerBackgroundLight: true })
        : this.setState({ footerBackgroundLight: false });
    }
    this.updateTitle(window.location.pathname);
  };

  render() {
    let { location, isFixed, video, footerBackgroundLight, loading } =
      this.state;

    let className = (() => {
      switch (location) {
        case LOCATION.HOME_PAGE:
          return "coming-soon";
        case LOCATION.FAQ_PAGE:
          return "faqs";
        case LOCATION.CONTACT_PAGE:
          return "contact";
        case LOCATION.WHITEPAPER_PAGE:
          return "whitepaper";
        case LOCATION.COMING_SOON_PAGE:
          return "coming-soon";
        default:
          return "not-found";
      }
    })();

    let gradientBackground =
      className === "contact" ? (
        <canvas id="mesh" width="16" height="16"></canvas>
      ) : (
        ""
      );

    return (
      <div className={isFixed ? "header-fixed " + className : className}>
        <div className="gradient-mesh">{gradientBackground}</div>
        <div onClick={() => this.titleHandler()}>
          <Navbar fixed={isFixed} />
        </div>
        <ComingSoon titleHandler={this.titleHandler} />
      </div>
    );
  }
}

export default App;
