import React from "react";
import { gsap } from "gsap";
import "./navbar.sass";

// import AuditReportPDF from 'files/0xBlock_Audit_Report.pdf'
import LogoImg from './assets/images/logo.png';

import { FaLinkedinIn, FaInstagram, FaDribbble, FaTwitter } from 'react-icons/fa'

const container = document.querySelector("#root");

class Navbar extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isCompact: false,
      menu: false,
    };
  }

  handleMenuToggle = () => {
    let menu = this.state.menu;
    this.setState({ menu: !menu });

    !menu
      ? gsap.to(".navigation__list", {
          duration: 0.3,
          ease: "power3.out",
          marginTop: 0,
        })
      : gsap.to(".navigation__list", {
          duration: 0.4,
          ease: "power3.out",
          marginTop: "-200%",
        });
  };

  handleMenuClose = () => {
    this.setState({ menu: false });

    gsap.to(".navigation__list", {
      duration: 0.2,
      ease: "power3.out",
      marginTop: "-200%",
    });

    window.scrollTo({
      top: 0,
    });
  };

  handleResize = () => {
    const windowWidth = container.getBoundingClientRect().width;
    windowWidth > 991
      ? this.setState({ isCompact: false })
      : this.setState({ isCompact: true });
  };

  componentDidMount() {
    const windowWidth = container.getBoundingClientRect().width;
    windowWidth > 991
      ? this.setState({ isCompact: false })
      : this.setState({ isCompact: true });
    window.addEventListener("resize", this.handleResize);
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    const { fixed } = this.props;
    if (
      !!fixed &&
      fixed !== prevProps.fixed &&
      window.location.pathname === "/"
    ) {
      gsap.to(".header .fade-in-right", {
        ease: "power3.out",
        duration: 0,
        opacity: 1,
        x: 0,
        delay: 0.5,
      });
      gsap.to(".head .fade-in", {
        ease: "power3.out",
        duration: 2,
        opacity: 1,
        x: 0,
        y: 0,
        delay: 0.8,
      });
    }
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.handleResize);
  }

  render() {
    let { isCompact, menu } = this.state;

    const { fixed } = this.props;
    const is_home_page = window.location.pathname === "/";
    const is_coming_soon_page = window.location.pathname === "/coming-soon";
    const animation_only_home_page = is_home_page && fixed;

    let menuButton = (
      <div
        onClick={this.handleMenuToggle}
        className={menu ? "menu header__menu active" : "menu header__menu"}
      >
        <div className="menu__shape menu__shape-1"></div>
        <div className="menu__shape menu__shape-2"></div>
      </div>
    );

    const handleAuditReport = () => {
      window.open("./files/0xBlock_Audit_Report.pdf", "_blank");
    };

    return (
      <nav className={this.props.fixed ? "header fixed" : "header"}>
        <div className="container">
          <div className="header__inner">
            <a
              onClick={this.handleMenuClose}
              className="logo header__logo"
              exact="true"
              href="/"
            >
              <div className="logo__icon">
                <img alt='' src={LogoImg} />
                </div>
              <span>FourthD</span>
            </a>
            {isCompact ? menuButton : ""}
            <div
              className={
                isCompact
                  ? "navigation header__navigation"
                  : "navigation header__navigation navigation-static"
              }
            >
              <ul className="navigation__list">
                {/* <li className="navigation__item">
                  <Link onClick={this.handleMenuClose} exact="true" to="/">Home</Link>
                </li> */}

                <li className="navigation__item d-lg-block d-md-none">
                  <a href="https://twitter.com/FourthD__" target="_blank">
                    <FaTwitter />
                  </a>
                </li>
                <li className="navigation__item d-lg-block d-md-none">
                  <a href="https://www.linkedin.com/company/fourthd/" target="_blank">
                    <FaLinkedinIn />
                  </a>
                </li>
                <li className="navigation__item d-lg-block d-md-none">
                  <a href="https://www.instagram.com/fourthddesign/" target="_blank">
                    <FaInstagram />
                  </a>
                </li>
                <li className="navigation__item d-lg-block d-md-none">
                  <a href="https://dribbble.com/fourthd" target="_blank">
                    <FaDribbble />
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </nav>
    );
  }
}

export default Navbar;
